import { BackendService } from '@/app/core/backend.service';
import { ErrorService } from '@/app/core/error-system/error.service';
import { RobotRtcConnectionManager } from './webrtc/rtc-connection-manager';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@/app/core/auth.service';
import { BackendActions } from './backend/backend-actions';
import { RtcEvents } from './webrtc/rtc-events';
import { RobotStatusSnoozingManager } from './local-logic/robot-status-snoozing-manager';
import { Finalizable, finalizeChildren } from '@/utils/finalizable';
import { SirenManager } from './local-logic/siren-manager';
import { InControlManager } from './local-logic/in-control-manager';
import { EnabledArrivedButton } from './local-logic/enabled-arrived-button';
import { RobotDerivedState } from './backend/robot-derived-state';
import { DataAcquisition } from './local-logic/data-acquisition';
import { CurbClimbing } from './local-logic/curb-climbing';
import { AutonomyRobotControl } from './local-logic/autonomy-robot-control';
import { OverlayMapState } from './local-logic/overlay-map-state';
import { ManualMouseControlState } from './local-logic/manual-mouse-control-state';
import { ManualControl } from './local-logic/manual-control';
import { RtcSendDataChannels } from './webrtc/rtc-send-data-channels';
import { VideoStream } from './webrtc/video-stream';
import { RtcStreamManager } from './webrtc/rtc-stream-manager';
import { RtcStats } from './webrtc/rtc-stats';
import { RtcNetworkInterfaces } from './webrtc/rtc-network-interfaces';
import { WebRtcManager } from './webrtc/webrtc-manager';
import { BackendStatePolling } from './backend/backend-state-polling';
import { SignalingService } from './webrtc/signaling.service';
import { SignalingConnection } from './webrtc/signaling-connection';
import { Observable, of } from 'rxjs';
import { HazardLightsState, Robot } from './backend/robot.dto';
import { Order } from '@/app/core/order/order';
import {
  ClientInfo,
  NetworkInterfaceStats,
} from './webrtc/signaling-server-messages';
import { RobotSystemStatus, RouteDto } from './backend/types';
import {
  GlobalPose,
  ImageCommand,
  ManualRobotControl,
  MapDataPreprocessingActions,
  RobotConnectionStats,
  TurnIndicatorState,
  TurnIndicatorDirection,
  VideoChannel,
  VideoChannelConfiguration,
} from './webrtc/types';
import { RouteCorridorConfirmationState } from '@/app/robots/robot-operation/common/corridor-confirmation/route-corridor-confirmation-state';
import { StatusTreeNode } from '@/app/robots/robot-operation/status-tree/status-tree.component';
import { takeUntil } from 'rxjs/operators';
import { AudioControl, RobotAudioState } from './audio-control';
import { InstructorModeManager } from './local-logic/instructor-mode-manager';
import { UserSessionService } from '../user-session/user-session.service';
import { RobotBlocking } from './backend/robot-blocking';
import { UserSessionEventTrackingService } from '../user-session/user-session-event-tracking.service';
import { CompartmentManager } from './backend/compartment-manager';
import { RobotSwapRequestsHandling } from './backend/robot-swap-requests-handling';
import {
  RobotActionRequest,
  RobotActionRequestManager,
  RobotActionRequestNotification,
} from './local-logic/action-request-manager';
import {
  UnsupervisedAutonomy,
  UnsupervisedAutonomyUIState,
} from './local-logic/unsupervised-autonomy';
import { UnsupervisedAutonomyActivation } from './backend/unsupervised-autonomy-activation';
import { RobotsBackendService } from './robots-backend.service';
import { LightsManager } from './local-logic/lights-manager';

export class RobotCommunication extends Finalizable {
  readonly connectedSince = new Date();

  // keep reference for cleanup
  private readonly signalingConnection: SignalingConnection;
  private readonly webRtcManager: WebRtcManager;

  readonly robotState$: Observable<Robot>;
  readonly robotRoute$: Observable<RouteDto>;
  readonly orders$: Observable<Order[]>;
  readonly isAlive$: Observable<boolean> = of(true);
  readonly isServingOrder$: Observable<boolean>;
  readonly unsupervisedAutonomyState$: Observable<
    UnsupervisedAutonomyUIState | undefined
  >;
  readonly isPowerSaving$: Observable<boolean>;
  readonly isAutomaticPowerSaving$: Observable<boolean>;
  readonly sirenOn$: Observable<boolean>;
  readonly desiredTurnIndicatorDirection$: Observable<TurnIndicatorDirection>;
  readonly turnIndicatorState$: Observable<TurnIndicatorState>;
  readonly controlledBy$: Observable<ClientInfo | undefined>;
  readonly connected$: Observable<boolean>;
  readonly enableArrivedButton$!: Observable<boolean>;
  readonly isInControl$: Observable<boolean>;
  readonly instructorModeActive$: Observable<boolean>;
  readonly hazardLightsState$!: Observable<HazardLightsState>;
  readonly desiredOperatorHazardLightsState$!: Observable<HazardLightsState>;
  readonly isCurbClimbingOn$!: Observable<boolean>;
  readonly readyForOrders$!: Observable<boolean>;
  readonly robotSystemStatuses$: Observable<RobotSystemStatus[]>;
  readonly latency$: Observable<number>;
  readonly useLegacyRouteCorridorConfirmation$: Observable<boolean>;
  readonly isManualMouseControl$: Observable<boolean>;
  readonly isAutonomyEnabled$: Observable<boolean>;
  readonly isOverlayMapEnabled$: Observable<boolean>;
  readonly globalPose$: Observable<GlobalPose>;
  readonly triggerServiceConfig$: Observable<unknown>;
  readonly manualControl$: Observable<ManualRobotControl | undefined>;
  readonly videoChannel$: Observable<VideoChannel>;
  readonly isReversing$: Observable<boolean>;
  readonly routeCorridorConfirmationState$: Observable<RouteCorridorConfirmationState>;
  readonly freeRobotDiskSpace$: Observable<number>;
  readonly mapDataPreprocessorState$: Observable<string | undefined>;
  readonly stats$: Observable<RobotConnectionStats>;
  readonly connectedNetworkInterface$: Observable<string | undefined>;
  readonly reliableDataChannelOpen$: Observable<boolean>;
  readonly videoElement: HTMLVideoElement;
  readonly robotAttentionReport$: Observable<string | undefined>;
  readonly selectedNetworkInterface$: Observable<string | undefined>;
  readonly availableNetworkInterfaces$: Observable<NetworkInterfaceStats[]>;
  readonly robotStatusTree$: Observable<StatusTreeNode[]>;
  readonly robotAudioState$ = of(RobotAudioState.NOT_AVAILABLE);
  readonly isRobotMuted$: Observable<boolean>;
  readonly robotAudioData$: Observable<Float32Array> = of(new Float32Array());
  readonly failedSubsequentConnectionAttemptsCount$: Observable<number>;
  readonly isBlockedForMillis$: Observable<number>;

  readonly blockedRobotIsReadyForFinalization$: Observable<boolean>;
  readonly robotActionRequestNotification$: Observable<
    RobotActionRequestNotification[]
  >;
  readonly routeToEndDistance$: Observable<number | null>;

  private readonly backendStatePolling: BackendStatePolling;
  private readonly rtcEvents: RtcEvents;
  // need to keep as property for finalization
  private readonly robotSwapRequestsHandling: RobotSwapRequestsHandling;
  private readonly unsupervisedAutonomyActivation: UnsupervisedAutonomyActivation;
  private readonly unsupervisedAutonomy: UnsupervisedAutonomy;
  private readonly robotDerivedState: RobotDerivedState;
  private readonly robotBlocking: RobotBlocking;
  private readonly backendActions: BackendActions;
  private readonly dataAcquisition: DataAcquisition;
  private readonly curbClimbing: CurbClimbing;
  private readonly robotStatusSnoozingManager: RobotStatusSnoozingManager;
  private readonly sirenManager: SirenManager;
  private readonly inControlManager: InControlManager;
  private readonly instructorModeManager: InstructorModeManager;
  private readonly enabledArrivedButton: EnabledArrivedButton;
  private readonly autonomyRobotControl: AutonomyRobotControl;
  private readonly overlayMapState: OverlayMapState;
  private readonly manualMouseControlState: ManualMouseControlState;
  private readonly manualControl: ManualControl;
  private readonly rtcSendDataChannels: RtcSendDataChannels;
  private readonly videoStream: VideoStream;
  private readonly rtcStreams: RtcStreamManager;
  private readonly rtcStat: RtcStats;
  private readonly rtcNetworkInterfaces: RtcNetworkInterfaces;
  private readonly rtcConnection: RobotRtcConnectionManager;
  private readonly audioControl: AudioControl;
  private readonly compartmentManager: CompartmentManager;
  private readonly lightsManager: LightsManager;
  private readonly robotActionRequestManager = new RobotActionRequestManager();

  constructor(
    readonly robotId: string,
    signalingService: SignalingService,
    private readonly userSessionEventTrackingService: UserSessionEventTrackingService,
    backendService: BackendService,
    robotsBackendService: RobotsBackendService,
    errorService: ErrorService,
    snackBar: MatSnackBar,
    authService: AuthService,
    userSessionService: UserSessionService,
  ) {
    super();
    this.robotActionRequestNotification$ =
      this.robotActionRequestManager.notification$;
    this.compartmentManager = new CompartmentManager(
      this.robotId,
      backendService,
      userSessionEventTrackingService,
      errorService,
    );
    this.signalingConnection = signalingService.getSignalingConnection(
      this.robotId,
    );
    this.webRtcManager = new WebRtcManager(this.signalingConnection);
    this.rtcSendDataChannels = new RtcSendDataChannels(this.webRtcManager);
    this.audioControl = new AudioControl(
      this.robotId,
      this.webRtcManager,
      this.rtcSendDataChannels,
      this.userSessionEventTrackingService,
    );
    this.rtcStreams = new RtcStreamManager(this.rtcSendDataChannels);
    this.videoStream = new VideoStream(
      this.rtcSendDataChannels,
      this.webRtcManager,
    );

    this.rtcStat = new RtcStats(this.webRtcManager, this.videoStream);
    this.rtcNetworkInterfaces = new RtcNetworkInterfaces(
      this.signalingConnection,
    );
    this.rtcConnection = new RobotRtcConnectionManager(
      { robotId: this.robotId, connectedSince: this.connectedSince },
      this.rtcStreams,
      this.userSessionEventTrackingService,
      this.webRtcManager,
      this.signalingConnection,
      this.rtcNetworkInterfaces,
    );
    this.rtcEvents = new RtcEvents(this.rtcStreams);
    this.backendStatePolling = new BackendStatePolling(
      this.robotId,
      robotsBackendService,
      errorService,
      userSessionService,
    );
    this.robotSwapRequestsHandling = new RobotSwapRequestsHandling(
      this.robotId,
      userSessionService,
      this.backendStatePolling,
      this.robotActionRequestManager,
      () => this.finalize(),
    );

    this.inControlManager = new InControlManager(
      this.rtcSendDataChannels,
      this.rtcStreams,
      authService,
    );

    this.unsupervisedAutonomyActivation = new UnsupervisedAutonomyActivation(
      this.robotId,
      this.backendStatePolling,
      userSessionService,
      this.robotActionRequestManager,
      this.inControlManager,
      () => this.finalize(),
    );

    this.unsupervisedAutonomy = new UnsupervisedAutonomy(
      this.robotId,
      this.backendStatePolling,
      this.robotActionRequestManager,
      this.rtcSendDataChannels,
      this.inControlManager,
      this.userSessionEventTrackingService,
    );

    this.curbClimbing = new CurbClimbing(this.rtcSendDataChannels);
    this.robotDerivedState = new RobotDerivedState(
      this.robotId,
      this.backendStatePolling,
    );
    this.robotBlocking = new RobotBlocking(
      robotId,
      backendService,
      this.backendStatePolling,
      userSessionEventTrackingService,
      this.robotActionRequestManager,
    );
    this.robotStatusSnoozingManager = new RobotStatusSnoozingManager(
      this.rtcEvents,
    );
    this.sirenManager = new SirenManager(this.rtcSendDataChannels);
    this.lightsManager = new LightsManager(
      this.rtcStreams,
      this.rtcSendDataChannels,
    );

    this.enabledArrivedButton = new EnabledArrivedButton(
      this.inControlManager.isInControl$,
      this.backendStatePolling.robotState$,
      this.robotDerivedState.isAlive$,
    );
    this.autonomyRobotControl = new AutonomyRobotControl(
      this.rtcSendDataChannels,
      this.inControlManager,
    );
    this.overlayMapState = new OverlayMapState();
    this.manualControl = new ManualControl(this.rtcSendDataChannels);
    this.instructorModeManager = new InstructorModeManager(
      this.inControlManager,
      this.manualControl,
    );
    this.manualMouseControlState = new ManualMouseControlState(
      this.inControlManager.isInControl$,
      this.instructorModeManager.instructorModeActive$,
    );
    this.dataAcquisition = new DataAcquisition(
      this.rtcSendDataChannels,
      this.rtcEvents,
      snackBar,
    );
    this.backendActions = new BackendActions(
      this.robotId,
      backendService,
      errorService,
    );

    this.connected$ = this.rtcConnection.connected$;
    this.failedSubsequentConnectionAttemptsCount$ =
      this.rtcConnection.failedSubsequentConnectionAttemptsCount$;

    this.latency$ = this.rtcStreams.latency$;
    this.controlledBy$ = this.rtcStreams.controlledBy$;

    this.reliableDataChannelOpen$ =
      this.rtcSendDataChannels.reliableDataChannelOpen$;

    this.robotAudioData$ = this.audioControl.robotAudioData$.pipe(
      takeUntil(this.finalized$),
    );
    this.robotAudioState$ = this.audioControl.robotAudioState$.pipe(
      takeUntil(this.finalized$),
    );
    this.isRobotMuted$ = this.audioControl.isRobotMuted$;

    this.stats$ = this.rtcStat.stats$;

    this.connectedNetworkInterface$ =
      this.rtcNetworkInterfaces.connectedNetworkInterface$;
    this.selectedNetworkInterface$ =
      this.rtcNetworkInterfaces.selectedNetworkInterface$;
    this.availableNetworkInterfaces$ =
      this.rtcNetworkInterfaces.availableNetworkInterfaces$;

    this.videoChannel$ = this.videoStream.videoChannel$;
    this.isReversing$ = this.videoStream.isReversing$;
    this.videoElement = this.videoStream.videoElement;

    this.robotAttentionReport$ = this.rtcEvents.robotAttentionReport$;
    this.freeRobotDiskSpace$ = this.rtcEvents.freeRobotDiskSpace$;
    this.mapDataPreprocessorState$ = this.rtcEvents.mapDataPreprocessorState$;
    this.routeCorridorConfirmationState$ =
      this.rtcEvents.routeCorridorConfirmationState$;
    this.triggerServiceConfig$ = this.rtcEvents.triggerServiceConfig$;
    this.globalPose$ = this.rtcEvents.globalPose$;
    this.useLegacyRouteCorridorConfirmation$ =
      this.rtcEvents.useLegacyRouteCorridorConfirmation$;
    this.robotStatusTree$ = this.rtcEvents.robotStatusTree$;

    this.robotState$ = this.backendStatePolling.robotState$;
    this.robotRoute$ = this.backendStatePolling.robotRoute$;
    this.orders$ = this.backendStatePolling.orders$;

    this.isPowerSaving$ = this.robotDerivedState.isPowerSaving$;
    this.isAutomaticPowerSaving$ =
      this.robotDerivedState.isAutomaticPowerSaving$;
    this.isAlive$ = this.robotDerivedState.isAlive$;
    this.isServingOrder$ = this.robotDerivedState.isServingOrder$;
    this.readyForOrders$ = this.robotDerivedState.readyForOrders$;
    this.unsupervisedAutonomyState$ =
      this.unsupervisedAutonomy.unsupervisedAutonomyState$;
    this.hazardLightsState$ = this.robotDerivedState.hazardLightsState$;
    this.desiredOperatorHazardLightsState$ =
      this.robotDerivedState.desiredOperatorHazardLightsState$;
    this.turnIndicatorState$ = this.lightsManager.turnIndicatorState$;
    this.desiredTurnIndicatorDirection$ =
      this.lightsManager.desiredTurnIndicatorDirection$;

    this.isBlockedForMillis$ = this.robotBlocking.isBlockedForMillis$;
    this.blockedRobotIsReadyForFinalization$ =
      this.robotBlocking.blockedRobotIsReadyForFinalization$;

    // exposes local frontend state
    this.sirenOn$ = this.sirenManager.sirenOn$;
    this.isCurbClimbingOn$ = this.curbClimbing.isCurbClimbingOn$;
    this.enableArrivedButton$ = this.enabledArrivedButton.enabledArrivedButton$;
    this.isAutonomyEnabled$ = this.autonomyRobotControl.isAutonomyEnabled$;
    this.isOverlayMapEnabled$ = this.overlayMapState.isOverlayMapEnabled$;
    this.manualControl$ = this.manualControl.manualControl$;

    // exposes a state derived from local and backend state
    this.isInControl$ = this.inControlManager.isInControl$;
    this.instructorModeActive$ =
      this.instructorModeManager.instructorModeActive$;
    this.robotSystemStatuses$ =
      this.robotStatusSnoozingManager.robotSystemStatuses$;
    this.isManualMouseControl$ =
      this.manualMouseControlState.isManualMouseControl$;
    this.routeToEndDistance$ = this.robotDerivedState.routeToEndDistance$;
  }

  startStreams() {
    this.rtcStreams.sendEnabledStreams();
    this.rtcSendDataChannels.sendReliable({
      videoStreamEnabled: true,
    });
    this.backendStatePolling.start();
  }

  stopStreams() {
    this.rtcStreams.sendDisableStreams();
    this.rtcSendDataChannels.sendReliable({
      videoStreamEnabled: false,
    });
    this.backendStatePolling.stop();
  }

  async arrivedAtStop() {
    await this.backendActions.arrivedAtStop();
  }

  async abortInfrastructureTransaction() {
    await this.backendActions.abortInfrastructureTransaction();
  }

  sendImageCommand(imageCommand: ImageCommand) {
    return this.rtcSendDataChannels.sendReliable({
      label: 'imageCommand',
      payload: imageCommand,
    });
  }

  enableCurbClimbingMode(curbClimbingMode: boolean): boolean {
    return this.curbClimbing.enableCurbClimbingMode(curbClimbingMode);
  }

  claimRobotControl(checked: boolean): boolean {
    return this.inControlManager.claimControl(checked);
  }

  sendCustomGpsLocation(location: google.maps.LatLng): boolean {
    return this.rtcSendDataChannels.sendReliable({
      label: 'customGpsLocation',
      payload: {
        latitude: location.lat(),
        longitude: location.lng(),
      },
    });
  }

  resetDataUploaderDataBudget(interfaceName: string) {
    this.rtcSendDataChannels.sendReliable({
      label: 'dataUploaderResetDataBudget',
      payload: {
        interfaceName: interfaceName,
      },
    });
  }

  addDataUploaderDataBudget(interfaceName: string, bytes: number) {
    this.rtcSendDataChannels.sendReliable({
      label: 'dataUploaderAddDataBudget',
      payload: {
        interfaceName: interfaceName,
        dataBudgetBytes: bytes,
      },
    });
  }

  async triggerDataCollectionSnapshot() {
    await this.dataAcquisition.triggerDataCollection();
  }

  reconfigureTriggerService(reconfigurationObj: any) {
    this.rtcSendDataChannels.sendReliable({
      label: 'updateTriggerServiceConfig',
      payload: reconfigurationObj,
    });
  }

  overrideSafetySupervisor(): boolean {
    return this.rtcSendDataChannels.sendReliable({
      label: 'obstacleOverride',
      payload: {},
    });
  }

  sendPathCorridor(
    action: 'add' | 'reset' | 'stop',
    [x, y]: [number, number],
    width: number,
  ): boolean {
    return this.rtcSendDataChannels.sendReliable({
      label: 'path_corridor',
      payload: {
        action: action,
        x,
        y,
        width: width,
      },
    });
  }

  sendClearPathCorridor() {
    return this.sendPathCorridor('stop', [0, 0], 0);
  }

  snoozeStatus(statusString: string) {
    this.robotStatusSnoozingManager.snoozeStatus(statusString);
  }

  enableAutonomy(isAutonomyEnabled: boolean) {
    this.autonomyRobotControl.enableAutonomy(isAutonomyEnabled);
  }

  enableOverlayMap(isOverlayMapEnabled: boolean) {
    this.overlayMapState.enableOverlayMap(isOverlayMapEnabled);
  }

  enableManualMouseControl(isManualMouseControl: boolean) {
    this.manualMouseControlState.enableManualMouseControl(isManualMouseControl);
  }

  setInstructorModeActive(active: boolean) {
    this.instructorModeManager.setInstructorModeActive(active);
  }

  sendLidStatus(isLocked: boolean): boolean {
    const isUpdated = this.rtcSendDataChannels.sendReliable({
      label: 'lidLocked',
      payload: isLocked,
    });
    this.backendStatePolling.triggerUpdate();
    return isUpdated;
  }

  async openCompartment(compartmentId: string): Promise<boolean> {
    return this.compartmentManager.openCompartment(compartmentId);
  }

  async closeCompartment(compartmentId: string): Promise<boolean> {
    return this.compartmentManager.closeCompartment(compartmentId);
  }

  sendMapDataPreprocessor(action: 'finish' | 'start'): boolean {
    return this.rtcSendDataChannels.sendReliable({
      label: 'mapDataPreprocessorRequest',
      payload: action,
    });
  }

  sendDrivetrainResetRequest() {
    this.rtcSendDataChannels.sendReliable({
      label: 'drivetrainReset',
      payload: {},
    });
  }

  sendEstopReleaseRequest() {
    this.rtcSendDataChannels.sendReliable({
      label: 'estopRelease',
      payload: {},
    });
  }

  sendHubReset(): boolean {
    return this.rtcSendDataChannels.sendReliable({
      label: 'hubReset',
      payload: {},
    });
  }

  sendPowerCycle(): boolean {
    return this.rtcSendDataChannels.sendReliable({
      label: 'powerCycle',
      payload: {},
    });
  }

  sendTareScale(status: boolean): boolean {
    return this.rtcSendDataChannels.sendReliable({
      label: 'tareScale',
      payload: status,
    });
  }

  async sendLightingCommand(desiredHazardLightsState: HazardLightsState) {
    await this.backendActions.sendLightingCommand(desiredHazardLightsState);
  }

  sendVideoQualityRequest(channelName?: VideoChannel, isHighQuality?: boolean) {
    this.videoStream.sendVideoQualityRequest(channelName, isHighQuality);
  }

  sendVideoConfiguration(videoConfig: VideoChannelConfiguration) {
    this.videoStream.sendVideoConfiguration(videoConfig);
  }

  enableSirenAndAlarm(value: boolean) {
    this.sirenManager.enableSirenAndAlarm(value);
  }

  turnUpHighBeams(value: boolean) {
    this.rtcSendDataChannels.sendReliable({
      label: 'turnUpHighBeams',
      payload: value,
    });
  }

  enableAutonomyFor(durationSeconds: number): boolean {
    return this.rtcSendDataChannels.sendReliable({
      label: 'autonomyEnabled',
      payload: { autonomyEnabledFor: durationSeconds },
    });
  }

  confirmRouteCorridorEdge(edgeIdToConfirm: number) {
    this.rtcSendDataChannels.sendReliable({
      label: 'confirmRouteCorridorEdgeId',
      payload: edgeIdToConfirm,
    });
  }

  revokeRouteCorridorConfirmation() {
    this.rtcSendDataChannels.sendReliable({
      label: 'revokeRouteCorridorConfirmation',
      payload: {},
    });
  }

  async setRobotReadyForOrdersState(readyForOrders: boolean) {
    await this.backendActions.setRobotReadyForOrdersState(readyForOrders);
  }

  async powerSaving(isPowerSaving: boolean): Promise<void> {
    await this.backendActions.powerSaving(isPowerSaving);
  }

  async automaticPowerSaving(automaticPowerSaving: boolean): Promise<void> {
    await this.backendActions.automaticPowerSaving(automaticPowerSaving);
  }

  async triggerSnapshot() {
    await this.dataAcquisition.triggerSnapshot();
  }

  controlManually(controlCommand: ManualRobotControl) {
    this.manualControl.manualControl(controlCommand);
  }

  relocalize() {
    this.rtcSendDataChannels.sendReliable({
      label: 'relocalize',
      payload: {},
    });
  }

  mapDataPreprocessorRequest(state: MapDataPreprocessingActions) {
    this.rtcSendDataChannels.sendReliable({
      label: 'mapDataPreprocessorRequest',
      payload: state,
    });
  }

  async selectNetworkInterface(selectedNetworkInterface: string) {
    await this.rtcConnection.selectNetworkInterface(selectedNetworkInterface);
  }

  async forceConnectionSwitch() {
    await this.rtcConnection.connect(true);
  }

  async resetConnection() {
    await this.rtcConnection.connect();
  }

  protected async onFinalize(): Promise<void> {
    await finalizeChildren(this);
  }

  setDesiredRobotAudioState(desiredRobotAudioState: RobotAudioState) {
    this.audioControl.setRobotAudioState(desiredRobotAudioState);
  }

  muteMicrophone(muted: boolean) {
    this.audioControl.muteMicrophone(muted);
  }

  muteRobot(muted: boolean) {
    this.audioControl.muteRobot(muted);
  }

  async sendCalibrateEndstopCommand() {
    await this.backendActions.setEndstopStateCommand('calibrate');
  }

  onActionClicked(notification: RobotActionRequestNotification) {
    this.robotActionRequestManager.onClick(notification);
  }

  requestRobotAction(newNotification: RobotActionRequest) {
    this.robotActionRequestManager.notify(newNotification);
  }

  removeRequestRobotAction(notificationActionTypeId: string) {
    this.robotActionRequestManager.removeNotification(notificationActionTypeId);
  }

  requestTurnIndicator(direction: TurnIndicatorDirection) {
    this.lightsManager.requestTurnIndicator(direction);
  }
}
