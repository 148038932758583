import { BehaviorSubject, of, partition } from 'rxjs';
import { exhaustMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { Finalizable } from '@/utils/finalizable';
import { visiblePageTimer } from '@/utils/page-visibility';
import { WebRtcManager } from './webrtc/webrtc-manager';
import { RtcSendDataChannels } from './webrtc/rtc-send-data-channels';
import { UserSessionEventTrackingService } from '../user-session/user-session-event-tracking.service';
import { UserSessionInteractionEventName } from '../user-session/user-session-interaction-events';

export enum RobotAudioState {
  NOT_AVAILABLE = 'not-available',
  DISABLED = 'disabled',
  SPEAKER_ACTIVE = 'speaker-active',
  AUDIO_LEVEL = 'audio-level',
}
export class AudioControl extends Finalizable {
  private readonly _isRobotMuted$ = new BehaviorSubject<boolean>(false);
  readonly isRobotMuted$ = this._isRobotMuted$.asObservable();

  private readonly _robotAudioState$ = new BehaviorSubject<RobotAudioState>(
    RobotAudioState.NOT_AVAILABLE,
  );
  readonly robotAudioState$ = this._robotAudioState$.asObservable();

  private readonly FFT_SIZE = 512;
  private readonly _robotAudioData$ = new BehaviorSubject<Float32Array>(
    new Float32Array(this.FFT_SIZE),
  );
  readonly robotAudioData$ = this._robotAudioData$.asObservable();

  private readonly _anaylyzerOn$ = new BehaviorSubject<boolean>(false);

  private robotAudioTrack?: MediaStreamTrack;
  private operatorAudioTrack?: MediaStreamTrack;

  private audioContext: AudioContext;
  private audioAnalyser: AnalyserNode;
  private audioNode?: MediaStreamAudioSourceNode;

  constructor(
    private readonly robotId: string,
    private readonly webRtcManager: WebRtcManager,
    private readonly dataChannels: RtcSendDataChannels,
    private readonly userSessionEventTrackingService: UserSessionEventTrackingService,
  ) {
    super();

    const audioContextType = window.AudioContext;
    this.audioContext = new audioContextType();
    this.audioAnalyser = this.audioContext.createAnalyser();
    this.audioAnalyser.fftSize = this.FFT_SIZE;
    this.audioAnalyser.smoothingTimeConstant = 0.1;

    this.webRtcManager.robotAudioTrack$
      .pipe(takeUntil(this.finalized$))
      .subscribe(async (mediaStreamTrack) => {
        this.robotAudioTrack = mediaStreamTrack;
        this.robotAudioTrack.enabled = false;
        this.audioNode?.disconnect();

        try {
          //--- START CHROME HACKE to enable audio context
          let a: HTMLAudioElement | null = new Audio();
          a.muted = true;
          a.srcObject = new MediaStream([mediaStreamTrack]);
          a.addEventListener('canplaythrough', () => {
            a = null;
            if (!this.robotAudioTrack) {
              return;
            }
            this.audioNode = this.audioContext.createMediaStreamSource(
              new MediaStream([this.robotAudioTrack]),
            );
            const currentState = this._robotAudioState$.getValue();
            this.setRobotAudioState(
              currentState === RobotAudioState.NOT_AVAILABLE
                ? RobotAudioState.AUDIO_LEVEL //Make this default
                : currentState,
            );
          });
        } catch (e) {
          console.error('Audio hack failed', e);
        }
        //---- END CHROME HACKE to enable audio context
      });

    this.webRtcManager.operatorAudioTrack$
      .pipe(takeUntil(this.finalized$))
      .subscribe(
        (operatorAudioTrack) => (this.operatorAudioTrack = operatorAudioTrack),
      );

    const [startAnalyzer$, stopAnalyzer$] = partition(
      this._anaylyzerOn$,
      Boolean,
    );
    startAnalyzer$
      .pipe(
        exhaustMap(() =>
          visiblePageTimer(0, 200).pipe(
            takeUntil(stopAnalyzer$),
            takeUntil(this.finalized$),
          ),
        ),
      )
      .subscribe(() => {
        if (!this.robotAudioTrack) {
          return;
        }
        const smoothed = new Float32Array(this.audioAnalyser.fftSize);
        this.audioAnalyser.getFloatTimeDomainData(smoothed);
        this._robotAudioData$.next(smoothed);
      });

    this._isRobotMuted$
      .pipe(
        takeUntil(this.finalized$),
        switchMap((muted) =>
          muted ? visiblePageTimer(0, 500).pipe(map(() => true)) : of(false),
        ),
      )
      .subscribe((muted) => {
        this.dataChannels.sendUnreliable({
          label: 'muteAudio',
          payload: muted,
        });
      });
  }

  async muteRobot(muted: boolean) {
    this.userSessionEventTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.MUTE_ROBOT,
      { robotId: this.robotId, muted },
    );
    this._isRobotMuted$.next(muted);
  }

  async setRobotAudioState(desiredRobotAudioState: RobotAudioState) {
    if (!this.robotAudioTrack) {
      return;
    }
    try {
      this.audioNode?.disconnect();
      this._anaylyzerOn$.next(false);
      this.robotAudioTrack.enabled = false;

      switch (desiredRobotAudioState) {
        case RobotAudioState.DISABLED:
          this.robotAudioTrack.enabled = false;
          break;

        case RobotAudioState.SPEAKER_ACTIVE:
          this.robotAudioTrack.enabled = true;
          this.audioNode?.connect(this.audioContext.destination);
          break;

        case RobotAudioState.AUDIO_LEVEL:
          this.robotAudioTrack.enabled = true;
          this.audioNode?.connect(this.audioAnalyser); //plays stream
          this._anaylyzerOn$.next(true);
          break;
      }
      this._robotAudioState$.next(desiredRobotAudioState);
    } catch (e) {
      console.error('Failed to set robot audio state', e);
    }
  }

  muteMicrophone(muted: boolean) {
    if (this.operatorAudioTrack) {
      this.operatorAudioTrack.enabled = !muted;
    }
  }

  protected async onFinalize(): Promise<void> {
    try {
      this.audioNode?.disconnect();
      await this.audioContext.close();
    } catch (e) {
      console.error('Failed to close audio', e);
    }
  }
}
