import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  MatSlideToggleChange,
  MatSlideToggle,
} from '@angular/material/slide-toggle';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {
  fromEvent,
  Observable,
  of,
  Subject,
  merge,
  firstValueFrom,
  lastValueFrom,
} from 'rxjs';
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  ConfirmationDialogData,
  ConfirmationDialogComponent,
} from '@/app/core/confirmation-dialog/confirmation-dialog.component';
import {
  Compartment,
  CompartmentState,
  HazardLightsState,
} from '../robots-service/backend/robot.dto';
import { hasInputChanged } from '@/utils/has-input-changes';
import { MapConfirmationDialogComponent } from '../confirmation-dialog/map-confirmation-dialog.component';
import { CompartmentsDialogComponent } from '../compartments-dialog/compartments-dialog.component';
import { RobotCommunication } from '../robots-service/robot-communication';
import { KeyboardButtonsRobotControl } from './keyboard-button-robot-controls';
import {
  CreateRobotIssueDialogComponent,
  CreateRobotIssueError,
  RobotIssue,
} from '../robot-issues';
import { HttpErrorResponse } from '@angular/common/http';
import { RobotAudioState } from '../robots-service/audio-control';
import { Router } from '@angular/router';
import { UserSessionEventTrackingService } from '../user-session/user-session-event-tracking.service';
import { UserSessionInteractionEventName } from '../user-session/user-session-interaction-events';
import {
  ReportBlockageDialogComponent,
  ReportBlockageDialogData,
} from '../report-blockage-dialog/report-blockage-dialog.component';
import { keyPressed } from '@/utils/key-pressed';
import { identity } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormField,
  MatLabel,
} from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { AudioLevelOverlayComponent } from './audio-level-overlay/audio-level-overlay.component';
import { CAsyncPipe } from '@/utils/c-async-pipe';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

const DATA_COLLECTION_NOTIFICATION_DURATION = 5 * 1000;

export type AutonomyControlType =
  | 'DISABLED'
  | 'AUTONOMY_TOGGLE'
  | 'PARKING_MODE';

export type HazardLightControlType =
  | 'DISABLED'
  | 'HAZARD_LIGHT_TOGGLE'
  | 'HAZARD_LIGHTS_DROPDOWN';

const ROBOT_AUDIO_STATE_MAPPING = {
  [RobotAudioState.DISABLED]: RobotAudioState.AUDIO_LEVEL,
  [RobotAudioState.AUDIO_LEVEL]: RobotAudioState.SPEAKER_ACTIVE,
  [RobotAudioState.SPEAKER_ACTIVE]: RobotAudioState.DISABLED,
  [RobotAudioState.NOT_AVAILABLE]: RobotAudioState.NOT_AVAILABLE,
} as const;

@Component({
  selector: 'app-robot-control-panel',
  templateUrl: './robot-control-panel.component.html',
  styleUrl: './robot-control-panel.component.sass',
  providers: [
    {
      // removes ugly space below the mat form field of the network switch
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
      },
    },
  ],
  imports: [
    AudioLevelOverlayComponent,
    CAsyncPipe,
    DecimalPipe,
    FormsModule,
    MatMenuItem,
    MatFormField,
    MatIcon,
    MatLabel,
    MatMenu,
    MatMenuTrigger,
    MatMiniFabButton,
    MatOption,
    MatSelect,
    MatSlideToggle,
    MatTooltip,
  ],
})
export class RobotControlPanelComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input()
  isClaimControlEnabled = true;

  @Input()
  isManualControlEnabled = true;

  @Input()
  autonomyControlType: AutonomyControlType = 'AUTONOMY_TOGGLE';

  @Input()
  isCurbClimbingControlEnabled = true;

  @Input()
  isReadyForOrderControlEnabled = true;

  @Input()
  hazardLightControlType: HazardLightControlType = 'HAZARD_LIGHTS_DROPDOWN';

  @Input()
  isNetworkInterfaceControlEnabled = true;

  @Input()
  isLidClockControlEnabled = true;

  @Input()
  isCompartmentControlEnabled = true;

  @Input()
  isArrivedControlEnabled = true;

  @Input()
  isOverlayMapControlEnabled = true;

  @Input()
  isBlockageReportEnabled = true;

  @Input()
  isSnapshotControlEnabled = true;

  @Input()
  isTriggerDataCollectionControlEnabled = true;

  @Input()
  isPowerSavingControlEnabled = false;

  @Input()
  isAutomaticPowerControlEnabled = false;

  @Input()
  isHighBeamsControlEnabled = true;

  @Input()
  isMuteRobotControlEnabled = true;

  @Input()
  isSirenControlEnabled = true;

  @Input()
  isIssueReportingEnabled = true;

  @Input()
  isNavigationToMaintenanceEnabled = false;

  @Input()
  isRelocalizeEnabled = false;

  @Input()
  isCalibrateEndstopEnabled = false;

  @Input()
  isHandlingSnapshotHotkeyEnabled = false;

  @Input()
  robotCommunication?: RobotCommunication;

  @Input()
  active = false;

  @Output()
  keyboardFocus = new EventEmitter<boolean>();

  private readonly unsubscribeRobot$ = new Subject<void>();

  hazardLightState$?: Observable<HazardLightsState>;

  readonly HAZARD_LIGHTS_STATES = Object.values(HazardLightsState);

  private keyboardRobotControl = new KeyboardButtonsRobotControl();

  private _destroy$ = new Subject();

  isNotInControl$!: Observable<boolean>;
  instructorModeActive$!: Observable<boolean>;
  isNotAutonomicPowerSavingControl$!: Observable<boolean>;
  robotSoundAvailable$ = of(false);
  speakerActive$!: Observable<boolean>;
  audioLevelActive$!: Observable<boolean>;

  compartmentsObservable$!: Observable<Compartment[]>;
  legacyLidControlActive = true;

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    readonly router: Router,
    private readonly userInteractionsTrackingService: UserSessionEventTrackingService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (hasInputChanged(changes, 'robotCommunication')) {
      this.unsubscribeRobot$.next(undefined);

      if (this.robotCommunication) {
        this.updateRobotCommunication(this.robotCommunication);
      }
    }

    this.keyboardRobotControl.setActive(this.active);
  }

  ngOnInit(): void {
    this.robotCommunication?.robotState$
      .pipe(takeUntil(this._destroy$))
      .subscribe((robotState) => {
        const compartments = robotState.compartments ?? [];
        this.isLocked = compartments.every(
          (c) => c.state === CompartmentState.CLOSED_AND_LOCKED,
        );
        this.legacyLidControlActive =
          compartments.length === 0 || compartments[0]?.id === 'legacy';
      });

    keyPressed('KeyS')
      .pipe(
        takeUntil(this._destroy$),
        map((sPressed) => sPressed && this.isHandlingSnapshotHotkeyEnabled),
        filter(identity),
      )
      .subscribe(async () => {
        await this.triggerSnapshot();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeRobot$.next(undefined);
    this.keyboardRobotControl.setActive(false);
    this._destroy$.next(undefined);
  }

  private updateRobotCommunication(robotCommunication: RobotCommunication) {
    this.isNotInControl$ = robotCommunication.isInControl$.pipe(
      map((isInControl) => !isInControl),
    );
    this.instructorModeActive$ = robotCommunication.instructorModeActive$;

    this.isNotAutonomicPowerSavingControl$ =
      robotCommunication.robotState$.pipe(
        map((robotState) => {
          return robotState.automaticPowerSaving !== true;
        }),
      );

    robotCommunication.finalized$.subscribe(() => {
      this.unsubscribeRobot$.next(undefined);
    });

    this.compartmentsObservable$ = merge(
      of([]),
      robotCommunication.robotState$.pipe(
        takeUntil(this._destroy$),
        takeUntil(this.unsubscribeRobot$),
        map((robotState) => robotState.compartments ?? []),
      ),
    );

    this.hazardLightState$ =
      robotCommunication.desiredOperatorHazardLightsState$;

    this.preventUnexpectedAutonomy(robotCommunication);

    this.keyboardRobotControl.claimControlToggle$
      .pipe(
        withLatestFrom(robotCommunication.isInControl$),
        map(([_, isInControl]) => isInControl),
        takeUntil(this.unsubscribeRobot$),
        filter(() => this.isClaimControlEnabled),
      )
      .subscribe((isInControl) => {
        this.setInControl(!isInControl);
      });

    this.keyboardRobotControl.manualMouseControlToggle$
      .pipe(
        withLatestFrom(robotCommunication.isManualMouseControl$),
        map(([_, isManualMouseControl]) => isManualMouseControl),
        takeUntil(this.unsubscribeRobot$),
        filter(() => this.isManualControlEnabled),
      )
      .subscribe((isManualMouseControl) => {
        const newManualMouseControlState = !isManualMouseControl;
        this.userInteractionsTrackingService.trackInteractionEvent(
          UserSessionInteractionEventName.ENABLE_MANUAL_MOUSE_CONTROL,
          {
            robotId: robotCommunication.robotId,
            isManualMouseControlEnabled: newManualMouseControlState,
          },
        );
        robotCommunication.enableManualMouseControl(newManualMouseControlState);
      });

    this.keyboardRobotControl.autonomyToggle$
      .pipe(
        takeUntil(this.unsubscribeRobot$),
        filter(() => this.autonomyControlType !== 'DISABLED'),
      )
      .subscribe(() => this.toggleIsAutonomyEnabled());

    this.keyboardRobotControl.triggerSnapshot$
      .pipe(
        takeUntil(this.unsubscribeRobot$),
        filter(() => this.isSnapshotControlEnabled),
      )
      .subscribe(async () => {
        await this.triggerSnapshot();
      });

    this.keyboardRobotControl.triggerDataCollection$
      .pipe(
        takeUntil(this.unsubscribeRobot$),
        filter(() => this.isTriggerDataCollectionControlEnabled),
      )
      .subscribe(async () => {
        this.snackBar.open('Triggering data collection snapshot!', undefined, {
          duration: DATA_COLLECTION_NOTIFICATION_DURATION,
        });
        this.userInteractionsTrackingService.trackInteractionEvent(
          UserSessionInteractionEventName.DATA_COLLECTION_TRIGGERED,
          { robotId: robotCommunication.robotId },
        );
        robotCommunication.triggerDataCollectionSnapshot();
      });

    this.robotSoundAvailable$ = robotCommunication.robotAudioState$.pipe(
      takeUntil(this.unsubscribeRobot$),
      map(
        (robotAudioState: RobotAudioState) =>
          robotAudioState !== RobotAudioState.NOT_AVAILABLE,
      ),
    );
    this.speakerActive$ = robotCommunication.robotAudioState$.pipe(
      takeUntil(this.unsubscribeRobot$),
      map(
        (robotAudioState: RobotAudioState) =>
          robotAudioState === RobotAudioState.SPEAKER_ACTIVE,
      ),
    );
    this.audioLevelActive$ = robotCommunication.robotAudioState$.pipe(
      takeUntil(this.unsubscribeRobot$),
      map(
        (robotAudioState: RobotAudioState) =>
          robotAudioState === RobotAudioState.AUDIO_LEVEL,
      ),
    );
  }

  async setInControl(inControl: boolean, event?: MatSlideToggleChange) {
    const controlledBy = await firstValueFrom(
      this.robotCommunication?.controlledBy$.pipe(take(1)) ?? of(undefined),
    );
    if (controlledBy !== undefined && inControl === true) {
      const confirmationResult = this.dialog
        .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(
          ConfirmationDialogComponent,
          {
            data: {
              message: `The robot is controlled by ${controlledBy.displayName}. Are you sure that you want to claim control?`,
            },
          },
        )
        .afterClosed();

      const confirmationResultBool = !!confirmationResult;

      this.robotCommunication?.claimRobotControl(confirmationResultBool);
      if (event !== undefined) {
        event.source.checked = confirmationResultBool;
      }
    } else {
      this.robotCommunication?.claimRobotControl(inControl);
    }
  }

  toggleIsAutonomyEnabled() {
    this.robotCommunication?.isAutonomyEnabled$
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe((isAutonomyEnabled) => {
        const newAutonomyState = !isAutonomyEnabled;
        this.userInteractionsTrackingService.trackInteractionEvent(
          UserSessionInteractionEventName.TOGGLE_IS_AUTONOMY_ENABLED,
          {
            robotId: this.robotCommunication?.robotId,
            isAutonomyEnabled: newAutonomyState,
          },
        );
        this.robotCommunication?.enableAutonomy(newAutonomyState);
      });
  }

  toggleIsOverlayMapEnabled() {
    this.robotCommunication?.isOverlayMapEnabled$
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe((isOverlayMapEnabled) => {
        const newOverlayMapState = !isOverlayMapEnabled;
        this.robotCommunication?.enableOverlayMap(newOverlayMapState);
        this.userInteractionsTrackingService.trackInteractionEvent(
          UserSessionInteractionEventName.TOGGLE_OVERLAY_MAP,
          {
            robotId: this.robotCommunication?.robotId,
            overlayMapEnabled: newOverlayMapState,
          },
        );
      });
  }

  async openReportBlockageDialog() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.REPORT_BLOCKAGE_DIALOG_OPENED,
      { robotId: this.robotCommunication?.robotId },
    );
    if (!this.robotCommunication) {
      return;
    }
    const config = new MatDialogConfig<ReportBlockageDialogData>();
    const currentRobot = await firstValueFrom(
      this.robotCommunication.robotState$.pipe(take(1)),
    );
    config.data = {
      currentLatitude: currentRobot.location.latitude,
      currentLongitude: currentRobot.location.longitude,
      currentAltitude: 0,
      currentHeading: currentRobot.heading,
    };
    this.dialog.open(ReportBlockageDialogComponent, config);
  }

  private preventUnexpectedAutonomy(robotCommunication: RobotCommunication) {
    // If control is claimed an autonomy is suppressed by the UI
    // via periodic signals stopping robot from movement
    //
    // Browsers tends to suppress all interval behaviors on blur
    // that can cause and unexpected autonomous movement of robots
    //
    // Therefore if the UI claims control and autonomy is not enabled
    // controls should be revoked as a temporary fix to prevent
    // unintended autonomous driving
    fromEvent(window, 'blur')
      .pipe(
        takeUntil(this.unsubscribeRobot$),
        withLatestFrom(
          robotCommunication.isAutonomyEnabled$ ?? of(false),
          robotCommunication.isInControl$,
        ),
      )
      .subscribe(([autonomyEnabled, isInControl]) => {
        if (isInControl && !autonomyEnabled) {
          robotCommunication.claimRobotControl(false);
        }
      });
  }

  arrivedAtStop() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.ARRIVED_AT_STOP_DIALOG_OPENED,
      { robotId: this.robotCommunication?.robotId },
    );
    this.dialog
      .open(MapConfirmationDialogComponent, {
        data: {
          robotCommunication: this.robotCommunication,
          message: `Confirm arrival?`,
        },
      })
      .afterClosed()
      .pipe(filter((isConfirmed: boolean) => isConfirmed))
      .subscribe(() => {
        this.userInteractionsTrackingService.trackInteractionEvent(
          UserSessionInteractionEventName.ARRIVED_AT_STOP_TRIGGERED,
          { robotId: this.robotCommunication?.robotId },
        );
        this.robotCommunication?.arrivedAtStop();
      });
  }

  togglePowerSaving() {
    this.robotCommunication?.isPowerSaving$
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe((isPowerSaving) => {
        const newPowerSavingState = !isPowerSaving;
        this.robotCommunication?.powerSaving(newPowerSavingState);
        this.userInteractionsTrackingService.trackInteractionEvent(
          UserSessionInteractionEventName.TOGGLE_POWER_SAVING,
          {
            robotId: this.robotCommunication?.robotId,
            powerSavingOn: newPowerSavingState,
          },
        );
      });
  }

  toggleRobotAudio() {
    if (!this.robotCommunication) {
      return;
    }

    this.robotCommunication.robotAudioState$
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe((robotAudioState) => {
        const newAudioState = ROBOT_AUDIO_STATE_MAPPING[robotAudioState];
        this.userInteractionsTrackingService.trackInteractionEvent(
          UserSessionInteractionEventName.TOGGLE_AUDIO_STATE,
          {
            robotId: this.robotCommunication?.robotId,
            audioState: newAudioState,
          },
        );
        this.robotCommunication?.setDesiredRobotAudioState(newAudioState);
      });
  }

  toggleAutomaticPowerSaving() {
    this.robotCommunication?.isAutomaticPowerSaving$
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe((isAutomaticPowerSaving) => {
        const newAutomaticPowerSavingState = !isAutomaticPowerSaving;
        this.userInteractionsTrackingService.trackInteractionEvent(
          UserSessionInteractionEventName.TOGGLE_AUTOMATIC_POWER_SAVING,
          {
            robotId: this.robotCommunication?.robotId,
            automaticPowerSavingOn: newAutomaticPowerSavingState,
          },
        );
        this.robotCommunication?.automaticPowerSaving(
          newAutomaticPowerSavingState,
        );
      });
  }

  isHighBeamsEnabled = false;

  toggleHighBeams() {
    this.isHighBeamsEnabled = !this.isHighBeamsEnabled;
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.TOGGLE_HIGH_BEAMS,
      {
        robotId: this.robotCommunication?.robotId,
        highBeamsOn: this.isHighBeamsEnabled,
      },
    );
    this.robotCommunication?.turnUpHighBeams(this.isHighBeamsEnabled);
  }

  isSirenOn = false;

  toggleSiren() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.TOGGLE_SIREN,
      { robotId: this.robotCommunication?.robotId, sirenOn: this.isSirenOn },
    );
    this.isSirenOn = !this.isSirenOn;
    this.robotCommunication?.enableSirenAndAlarm(this.isSirenOn);
  }

  isLocked!: boolean;

  toggleLock() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.COMPARTMENT_LOCK_STATUS_UPDATE,
      { robotId: this.robotCommunication?.robotId, isLocked: this.isLocked },
    );
    this.robotCommunication?.sendLidStatus(this.isLocked);
  }

  selectNetworkInterface(networkInterface: string) {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.SELECT_NETWORK_INTERFACE,
      { robotId: this.robotCommunication?.robotId, networkInterface },
    );
    this.robotCommunication?.selectNetworkInterface(networkInterface);
  }

  sendLightingCommand(hazardLightState: HazardLightsState) {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.TOGGLE_HAZARD_LIGHT_MODE,
      { robotId: this.robotCommunication?.robotId, hazardLightState },
    );
    this.robotCommunication?.sendLightingCommand(hazardLightState);
  }

  setRobotReadyForOrder(isReady: boolean) {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.SET_READY_FOR_ORDERS,
      { robotId: this.robotCommunication?.robotId, readyForOrders: isReady },
    );
    this.robotCommunication?.setRobotReadyForOrdersState(isReady);
  }

  enableManualMouseControl(isManualMouseControl: boolean) {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.ENABLE_MANUAL_MOUSE_CONTROL,
      {
        robotId: this.robotCommunication?.robotId,
        isManualMouseControlEnabled: isManualMouseControl,
      },
    );
    this.robotCommunication?.enableManualMouseControl(isManualMouseControl);
  }

  toggleCurbClimbing(isCurbClimbing: boolean) {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.ENABLE_CURB_CLIMBING_MODE,
      { robotId: this.robotCommunication?.robotId, isCurbClimbing },
    );
    this.robotCommunication?.enableCurbClimbingMode(isCurbClimbing);
  }

  async toggleHazardLightMode() {
    if (!this.hazardLightState$) {
      return;
    }
    const currentHazardsState = await lastValueFrom(
      this.hazardLightState$.pipe(take(1), takeUntil(this._destroy$)),
    );
    const newHazardsState =
      currentHazardsState !== HazardLightsState.HAZARD
        ? HazardLightsState.HAZARD
        : HazardLightsState.AUTO;
    this.sendLightingCommand(newHazardsState);
  }

  openCompartmentsDialog() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.COMPARTMENT_DIALOG_OPENED,
      { robotId: this.robotCommunication?.robotId },
    );
    if (!this.robotCommunication) {
      return;
    }
    this.dialog.open(CompartmentsDialogComponent, {
      autoFocus: true,
      data: {
        robotId: this.robotCommunication.robotId,
      },
    });
  }

  async triggerSnapshot() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.SNAPSHOT_TRIGGER,
      { robotId: this.robotCommunication?.robotId },
    );
    await this.robotCommunication?.triggerSnapshot();
  }

  async openRobotIssue(): Promise<void> {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.ISSUE_DIALOG_OPENED,
      { robotId: this.robotCommunication?.robotId },
    );
    if (!this.robotCommunication || !this.robotCommunication?.robotId)
      throw new Error('Cannot create issue for a non-existing Robot!');

    // For some reason it sometimes has query params? (O_O)
    const robotId = this.robotCommunication.robotId.split('?')[0];
    if (!robotId) throw new Error('Cannot find RobotID within the connection!');

    const snackOptions: MatSnackBarConfig = { verticalPosition: 'top' };

    this.dialog
      .open(CreateRobotIssueDialogComponent, {
        hasBackdrop: true,
        data: {
          robotId,
        },
      })
      .afterClosed()
      .subscribe(
        async (
          robotIssue: Promise<RobotIssue | CreateRobotIssueError> | undefined,
        ) => {
          if (!robotIssue) return;

          const loader = this.snackBar.open(
            'Submitting issue...',
            '',
            snackOptions,
          );

          try {
            const issue = await robotIssue;
            if ('message' in issue) {
              this.userInteractionsTrackingService.trackInteractionEvent(
                UserSessionInteractionEventName.ISSUE_REPORT_FAILED,
                {
                  robotId: this.robotCommunication?.robotId,
                  message: issue.message,
                  code: issue.code,
                },
              );
              return;
            }
            this.userInteractionsTrackingService.trackInteractionEvent(
              UserSessionInteractionEventName.ISSUE_REPORTED,
              {
                robotId: this.robotCommunication?.robotId,
                issueId: issue.id,
                customId: issue.customId,
                severity: issue.severity,
                name: issue.name,
              },
            );
            const reference = issue.customId ?? issue.id;
            this.snackBar.open(
              `Issue has been reported! ${
                reference && `Reference code: ${reference}`
              }`,
              'Close',
              snackOptions,
            );
          } catch (thrown: HttpErrorResponse | unknown) {
            if (thrown instanceof HttpErrorResponse)
              this.snackBar.open(
                `Failed to report issue: ${
                  (thrown.error as { message: string }).message
                }`,
                'Close',
                snackOptions,
              );
          } finally {
            loader.dismiss();
          }
        },
      );
  }

  async openMaintenanceUi() {
    const robotId = this.robotCommunication?.robotId;
    if (robotId === undefined) {
      return;
    }
    await this.router.navigate(['/robots/supervise/' + robotId], {
      queryParams: { active: robotId },
    });
    window.location.reload();
  }

  async relocalize() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.RELOCALIZE_DIALOG_OPENED,
      { robotId: this.robotCommunication?.robotId },
    );
    const confirmationResult = await firstValueFrom(
      this.dialog
        .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(
          ConfirmationDialogComponent,
          {
            data: {
              message: `Are you sure you want to relocalize?`,
            },
          },
        )
        .afterClosed(),
    );
    if (confirmationResult === true) {
      this.userInteractionsTrackingService.trackInteractionEvent(
        UserSessionInteractionEventName.RELOCALIZE_TRIGGERED,
        { robotId: this.robotCommunication?.robotId },
      );
      await this.robotCommunication?.relocalize();
    }
  }

  async calibrateEndstop() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.CALIBRATE_END_STOP_DIALOG_OPENED,
      { robotId: this.robotCommunication?.robotId },
    );
    const confirmationResult = await firstValueFrom(
      this.dialog
        .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(
          ConfirmationDialogComponent,
          {
            data: {
              message: `Are you sure you want to calibrate endstop/IMU?`,
            },
          },
        )
        .afterClosed(),
    );
    if (confirmationResult === true) {
      await this.robotCommunication?.sendCalibrateEndstopCommand();
      this.userInteractionsTrackingService.trackInteractionEvent(
        UserSessionInteractionEventName.CALIBRATE_END_STOP_TRIGGERED,
        { robotId: this.robotCommunication?.robotId },
      );
    }
  }
}
